.quiensoy {
    background: $blue4;
    padding: $gutter;
    @include breakpoint($small) {
        padding: $gutter * 2;
    }
    position:relative;
    @include breakpoint($medium) {
        .section__mitad {
            height: calc(100vh - #{$gutter} * 4);
        }
        .section__mitad__right {
            position:absolute;
            top:0;
            right:0;
            left:50%;
            bottom:0;
        }
    }
}

.quiensoy__text {
    margin:0;
    height: 100%;
    max-height:100%;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    h1, p {
        color: $white !important;
    }
}
@include breakpoint($medium) {
    .quiensoy__text__wrapper {
        padding-right: $gutter * 4;
    }
    .quiensoy__body__wrapper {
        flex:1 0 auto;
        overflow:auto;
        position:relative;
    }
    .quiensoy__body {
        @include layer();
    }
}

.scroll-enabled {
    overflow:auto;
}