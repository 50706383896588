// ------
// Use get-color('palette', 'color') in your .scss files to get values
// For example: getColor('base', 'primary') => #C65146
// Use contrast-color($color) to get your black || white color against a background
// ------

/*
Color Palette

The palettes are based on palx.jxnblk.com (for example, https://palx.jxnblk.com/C7FFC4). Every single palette lives on a separate `.scss` file under 
`base/palettes` and is `@import`ed on `_config.scss` for later use on the `$colors` map.

This `$colors` map can store as many maps as needed, but the following are mandatory:

- base / for primary, secondary and separator colors
- text / for typography colors
- state / for indicating states like: muted, error, warning, success

Following palx.jxnblk.com nomenclature, the colors are numbered from 0 to 9 (lightest to darkest).

Markup:
<section>
	<p class="minititle">Base</p>
	<div class="grid grid--gutterless grid--small--2col grid--medium--6col grid--large--3col">
		<div class="grid-item p bg-base-primary">Primary</div>
		<div class="grid-item p bg-base-secondary" >Secondary</div>
		<div class="grid-item p bg-base-separator" >Separator</div>
		<div class="grid-item p bg-base-black" >Black</div>
		<div class="grid-item p bg-base-white" >White</div>
	</div>
</section>
<section>
	<p class="minititle">Text</p>
	<div class="grid grid--gutterless grid--small--2col grid--large--3col">
		<div class="grid-item p bg-text-primary">Primary</div>
		<div class="grid-item p bg-text-heading" >Heading</div>
		<div class="grid-item p bg-text-mark" >Mark</div>
	</div>
</section>
<section>
	<p class="minititle">State</p>
	<div class="grid grid--gutterless grid--small--2col grid--large--4col">
		<div class="grid-item p bg-state-muted">Muted</div>
		<div class="grid-item p bg-state-success" >Success</div>
		<div class="grid-item p bg-state-warning" >Warning</div>
		<div class="grid-item p bg-state-error" >Error</div>
	</div>
</section>

Styleguide Color.palette
*/

@import "base/palettes/772e25";

$colors: (
	base: (
		"primary": $red5,
		"secondary": $red4,
		"separator": $gray2,
		"black": $gray9,
		"white": #fff,
	),

	text: (
		"primary":   $gray9,
		"heading":   $gray9,
		"mark" : $orange5,
	),

	background: (
		"dark":  $gray8,
		"dim" : $gray1,
		"light": $gray0,
	),

	state: (
		"muted":   $gray1,
		"success": $teal4,
		"warning": $yellow5,
		"error":   $red5
	),

	blue: (
		"0" : $blue0,
		"1" : $blue1,
		"2" : $blue2,
		"3" : $blue3,
		"4" : $blue4,
		"5" : $blue5,
		"6" : $blue6,
		"7" : $blue7,
		"8" : $blue8,
		"9" : $blue9,
	),

	green: (
		"0" : $teal0,
		"1" : $teal1,
		"2" : $teal2,
		"3" : $teal3,
		"4" : $teal4,
		"5" : $teal5,
		"6" : $teal6,
		"7" : $teal7,
		"8" : $teal8,
		"9" : $teal9,
	),

	red: (
		"0" : $red0,
		"1" : $red1,
		"2" : $red2,
		"3" : $red3,
		"4" : $red4,
		"5" : $red5,
		"6" : $red6,
		"7" : $red7,
		"8" : $red8,
		"9" : $red9,
	),

	gray: (
		"0" : $gray0,
		"1" : $gray1,
		"2" : $gray2,
		"3" : $gray3,
		"4" : $gray4,
		"5" : $gray5,
		"6" : $gray6,
		"7" : $gray7,
		"8" : $gray8,
		"9" : $gray9,
	)
) !default;

// Quick access to black and white
$black: get-color(base, "black");
$white: get-color(base, "white");

// CONTRAST BLACK AND WHITE / Do not remove
// This values are used as return values for contrast-color()
$contrast-dark: #1A1A19;
$contrast-light: #FFFFFF;

// Global % to apply to darken and lighten operations
$color-modify-ratio: 33%;

// Wether to generate helper classes or not.
// Set this to false to avoid bloating your css
$generate-color-classes: true;



// --------------------
// VERTICAL RHYTHM
// Using Ritmo.scss
// --------------------

$base-font-size: 16px;
$vertical-rhythm: 1.35;
$base-line-height: $base-font-size * $vertical-rhythm;
$base-line-multi: 4;
$base-line-unit: $base-line-height / $base-line-multi;
$ritmo-unit: 'rem';
$rem-with-px-fallback: true;
$ritmo-border-width: 1px;
$round-to-nearest-half-size: true;

// Show a grid to see vertical rhythm in action
$show-grid: false;

// A "spacer" is a basic unit for both vertical and horizontal spacing
$spacer: ritmo(4); 





// ------
// TYPOGRAPHY
// ------

// Web-safe fonts library
$helvetica: Helvetica, Arial sans-serif;
$arial: Arial, Helvetica, sans-serif;
$lucida-sans: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
$tahoma: Tahoma, Geneva, sans-serif;
$trebuchet: "Trebuchet MS", Helvetica, sans-serif;
$verdana: Verdana, Geneva, sans-serif;

$courier: Courier, monospace;
$courier-new: "Courier New", Courier, monospace;
$lucida-console: "Lucida Console", Monaco, monospace;

$palatino: "Palatino Linotype", "Book Antiqua", Palatino, serif;
$times-new-roman: "Times New Roman", Times, Georgia, serif;
$times: "Times New Roman", Times, Georgia, serif;
$georgia: Georgia, serif;

// Custom fonts
$krub: 'Krub', $helvetica;
$merriweather: 'Merriweather', $georgia;

$fira-sans: 'Fira Sans', $helvetica;
$lato: 'Lato', $helvetica;

$vt323 : 'VT323', monospace;
$inconsolata: 'Inconsolata', monospace;

$staatliches: 'Staatliches', cursive;
$montserrat :  'Montserrat', sans-serif;

$cassanet: "Cassanet", $helvetica;
$noway: "Noway", $verdana;
$calendas: 'Calendas Plus', $georgia;

$muli: "Muli", $helvetica;





// Global variables. It is good practice (performance & design) to only use one or two typefaces.
$font-main: $muli;
$font-secondary: $cassanet;
$font-monospace: $inconsolata;

// Custom values for bold and light allow for more precise mapping
$bold: bold;
$font-main-bold: 700;
$font-secondary-bold: 100;
$light: light;
$normal: normal;

// List of all elements which use $font-secondary.
$font-secondary-items:
	h1, h2, h3, h4, h5, h6, dt,
	'.main-menu',
	'#your-ids';

// Heading overrides
// Select a font weight and an alternate line height for your headings
$heading-font-weight: normal;
$heading-vertical-rhythm:1; // We might want a slower VR for headings. Set to false if not.
$heading-letter-spacing: -0.03em; // Headings benefit of a reduced letter-spacing 

// Text alignment for some elements
$caption-align: left;
$figcaption-align: left;

// Quotes
// Here are some common quotes: “ ” ‘ ’ ‛ ‟ ‚ „ ' " ‹ › « »
$quotes: "“" "”" "‘" "’";

// Code-like items
$code-background: get-color(base, separator);
$code-border-radius: 2px;

// Letter-spacing (always EM units to keep it relative to the element scope)
$letter-spacing: 0.05em; 

// List paddings. We recommend either 1em or 0.
$list-padding: 0;





// ------
// TYPE SCALE
// Source: http://type-scale.com/ and concise.css
// ------

$minor-second:     1.067 !default;
$major-second:     1.125 !default;
$minor-third:      1.200 !default;
$major-third:      1.250 !default;
$perfect-fourth:   1.333 !default;
$augmented-fourth: 1.414 !default;
$perfect-fifth:    1.500 !default;
$golden-ratio:     1.618 !default;

// Choose your type-scale ratio
$scale-ratio: $perfect-fourth !default;

// Uses concise.css typeScale mixin to map font-sizes
$h1-font-size: typeScale(6);
$h2-font-size: typeScale(5);
$h3-font-size: typeScale(4);
$h4-font-size: typeScale(3);
$h5-font-size: typeScale(2); // Equals base-font-size
$h6-font-size: typeScale(1);

// Set font sizes of specific, size-aware elements
$sub-font-size: $h6-font-size;
$small-font-size: $h6-font-size;
$figcaption-font-size: $h6-font-size;







// ------
// COMMON ELEMENTS
// ------
// Some common patterns
// ------

$border-radius: 0px;

$separators: true;
$separator-width: 1px;
$separator-color: get-color(base, separator);

$table-border: 1px solid $separator-color; // CSS value or none
$table-padding: $spacer / 2;
$table-th-align: left;

$blockquote-whitespace: $spacer * 2;
$blockquote-decoration: '*';
$blockquote-decoration-size: $h2-font-size;
$blockquote-font: $font-secondary;

$shadows: true;
$shadow-definition: 2px 2px 2px 0px rgba($black, 0.1);




// ------
// LINKS
// ------
// This values are used by set-link() to get your linking strategy
// There are four link strategies available.
// ------

$link-strategy: underline; // Alternatives: underline, border, smart, mark, false
$link-highlight-on-hover: false; // "false" highlights the link before :hover

// Only for $link-strategy:border
$link-border-color: $violet2;
$link-border-width: ritmo(0.5);

// Only for $link-strategy:mark
$link-mark-color: $violet2;

// Only for $link-strategy:smart
$link-smart-background: $violet2;

// Link colors
$link-color: $red9;
$link-visited: $red9;
$link-hover: $red7;
$link-active: $red7;

// Inverse link colors (for contrasted backgrounds)
$inverse-link-color: $white;
$inverse-link-visited: $white;
$inverse-link-hover: $blue2;
$inverse-link-active: $blue2;




// ------
// FORMS
// ------

// General reset
$form-border-radius: $border-radius;

// Inputs
$input-border-strategy: none; // Options: full, bottom, none;
$input-border-color: get-color(gray, "2");
$input-border: 1px solid $input-border-color;
$input-padding: $spacer / 4 $spacer / 2;

// Buttons
$button-font-family: $font-main;
$button-strategy: 'border'; // Options: border, background
$button-strategy-border: 'fill'; // Only if $button-strategy = border. Options: fill, highlight
$button-color: $gray3;
$button-border-width: 2px;
$button-border: $button-border-width solid $button-color;
$button-padding: 0 ($spacer / 2);
$button-border-radius: 2px; // $border-radius;
$button-weight: $bold;
$button-invert-color: false; // ONly valid on border:fill and background

// Checkboxes / Selects
$pseudo-checkbox : false;
$pseudo-radio: false;
$pseudo-border-color: get-color(text, primary);
$pseudo-border: 1px solid $pseudo-border-color;

// Selects
$select-border-strategy: full; // Options: full, bottom, none;
$select-border-color: shade(get-color(gray, "5"), 25%);
$select-border: 1px solid $input-border-color;
$select-padding: 0 ($spacer / 2);

// Browser quirks
$webkit-autofill-color: get-color(gray, "5"); // When webkit fills the fields whith your stored data.

// Fieldsets
$disable-fieldset: false;


// ------
// NAVIGATION
// Default menu appearance
// ------

$nav-font-weight: bold;
$nav-uppercase: true;
$nav-font-size: $base-font-size;
$nav-spacing: $letter-spacing;

$toc-font-weight: bold;
$toc-uppercase: false;
$toc-font-size: $h5-font-size;

$menu-padding: $spacer / 2;
$menu-kill-links: true;
$menu-background-color: get-color(base, secondary);


// ------
// LAYOUT
// We asume a centered, responsive and max-width limited viewport.
// ------

$max-width: 1500px;
$layout-align: center; // left, right





// ------
// Z-INDEX
// To use in pair with the z-index() function
// ------

$z-index : (
	item1 : 0,
	item2 : 1,
);





// ------
// BREAKPOINTS
// ------

// Map for iterations
$breakpoints: (
	small : 640px,
	medium : 960px,
	large : 1120px
);

// Helpers for a simpler use on breakpoint() mixin
$small: map-get($breakpoints, small);
$medium: map-get($breakpoints, medium);
$large: map-get($breakpoints, large);





// ------
// ANIMATIONS && TRANSITIONS
// ------

$transitions: true;
$transition-time: 0.33s;
$transition-delay: 0.25s;

$disabled-opacity: 0.33;





// ------
// ALIASES
// Because sometimes you remember the wrong / old / deprecated word...
// ------

$gutter: $spacer;





// ------
// CARDS
// Component for indvidual, card-like componentes
// ------

$card-border: 1px solid $separator-color;// Can be false or a full border value, like 2px solid $separator-color;
$card-background: $white;
$card-border-radius: $border-radius;
$card-padding: $gutter * 2;



// ------
// YOUR PROJECT
// Project-specific variables
// ------



// ------
// KSS-NODE
// This is to be parsed with KSS-Node and assign custom weight to elements
// ------

// Color
//
// Weight: 1
//
// Styleguide Color

// Base
//
// Weight: 2
//
// Styleguide Base

// Typography
//
// Weight: 3
//
// Styleguide Typography

// Components
//
// Weight: 4
//
// Styleguide Components

// Layout
//
// Weight: 5
//
// Styleguide Layout

// Forms
//
// Weight: 6
//
// Styleguide Forms

// Helpers
//
// Weight: 7
//
// Styleguide Helpers

// Mixins
//
// Weight: 8
//
// Styleguide Mixins

// Functions
//
// Weight: 9
//
// Styleguide Functions

// Pseudo-elements
//
// Weight: 10
//
// Styleguide Pseudo-elements

// Huesitos
//
// Provide quick helper classes for common cases (like adding margin, padding or borders)
//
// Weight: 11
//
// Styleguide Huesitos

