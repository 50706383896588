.main-menu {
    margin:0;
    text-align:center;
    position:absolute;
    right:$gutter;
    left: $gutter;
    bottom: $gutter;
    @include breakpoint($small) {
        bottom: $gutter * 2;
        left: $gutter * 2;
        right:auto;
        text-align:left;
    }
    a {
        text-transform: uppercase;
        @include ritmo-font-size($h2-font-size);
        @include breakpoint($small) {
            @include ritmo-font-size($h1-font-size);
        }
        &.current {
            font-weight:bold;
            &:before {
                content:"";
                position:absolute;
                background-color: $orange3;
                width:200%;
                height:100%;
                top:0;
                right:- $gutter / 2;
                z-index:-1;
            }
            &:hover:before {
                background-color: $orange1;
            }
        }
    }
    &.main-menu--inverse {
        a {
            @include kill-link($black);
        }
    }
    a {
        @include kill-link($white);
        position:relative;
        z-index:0;
        display:inline-block;
        line-height:1;
        &:hover, 
        &:focus {
            &:before {
                content:"";
                position:absolute;
                background-color: $orange3;
                width:200%;
                height:100%;
                top:0;
                right:- $gutter / 2;
                z-index:-1;
            }
        }
    }
}