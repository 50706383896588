.contacto {
    padding: $gutter;
    @include breakpoint($small) {
        padding: $gutter * 2;
    }
    margin:0;
    background-color: $violet4;
    h1, p {
        color: $white;
    }  
}

.contacto__inner {
    display:flex;
    flex-direction:column;
    @include breakpoint($small) {
        flex-direction:row;
        flex-wrap: wrap;
        justify-content:space-between;
    }
    height:100%;
}

.contacto__info  {
    order: -999;
    text-align:right;
    @include breakpoint($small) {
        order: initial;
    }
}

.contacto__form {
    margin-bottom: $gutter;
    @include breakpoint($small) {
        margin-bottom:0;
    }
}

.contacto__form,
.contacto__info {
    @include breakpoint($small) {
        width:49%;
        margin-bottom:$gutter * 2;
    }
}
.contacto__map {
    width:100%;
    height:0;
    padding-bottom: 27%;
    position:relative;
    iframe {
        @include layer();
    }
}

.form-item {
    background-color: rgba($white, 0.85);
    input[type="text"],
    input[type="email"],
    textarea {

    }
}