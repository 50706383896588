$base: #772e25;
$black: #4b3b39;
$gray0: #f9f9f9;
$gray1: #eeedec;
$gray2: #e3dfdf;
$gray3: #d6d1d1;
$gray4: #c8c2c1;
$gray5: #b8b1b0;
$gray6: #a79d9c;
$gray7: #928685;
$gray8: #776867;
$gray9: #4b3b39;
$red0: #f2ebea;
$red1: #e3d4d3;
$red2: #d3bbb8;
$red3: #bf9c98;
$red4: #a4736e;
$red5: #772d25;
$red6: #6a2821;
$red7: #5c221c;
$red8: #4a1c17;
$red9: #32130f;
$orange0: #f0ede7;
$orange1: #e0d9ce;
$orange2: #cec2b1;
$orange3: #b9a88f;
$orange4: #9e8764;
$orange5: #775625;
$orange6: #6a4d21;
$orange7: #5c431c;
$orange8: #4b3617;
$orange9: #342510;
$yellow0: #eeefe6;
$yellow1: #dcdecb;
$yellow2: #c8cbac;
$yellow3: #b1b589;
$yellow4: #949a5e;
$yellow5: #6f7725;
$yellow6: #636b21;
$yellow7: #575d1d;
$yellow8: #474c17;
$yellow9: #323510;
$lime0: #eaf0e7;
$lime1: #d4dfcc;
$lime2: #baccae;
$lime3: #9db78c;
$lime4: #789c61;
$lime5: #467725;
$lime6: #3e6b21;
$lime7: #365d1d;
$lime8: #2c4c17;
$lime9: #1f3510;
$green0: #e7f0e8;
$green1: #cee0cf;
$green2: #b0ceb3;
$green3: #8eb892;
$green4: #639d69;
$green5: #25772d;
$green6: #216b28;
$green7: #1d5d23;
$green8: #174c1c;
$green9: #103514;
$teal0: #e7f0ec;
$teal1: #cde0d8;
$teal2: #b0cdc1;
$teal3: #8db8a6;
$teal4: #629d85;
$teal5: #257756;
$teal6: #216b4d;
$teal7: #1d5d43;
$teal8: #174c37;
$teal9: #103526;
$cyan0: #e7eff0;
$cyan1: #cddee0;
$cyan2: #b0cbce;
$cyan3: #8eb4b8;
$cyan4: #63989d;
$cyan5: #256f77;
$cyan6: #21636b;
$cyan7: #1c565d;
$cyan8: #17474c;
$cyan9: #103135;
$blue0: #e9ecf1;
$blue1: #d1d8e2;
$blue2: #b6c1d1;
$blue3: #95a5bd;
$blue4: #6b81a3;
$blue5: #254677;
$blue6: #213e6a;
$blue7: #1c365b;
$blue8: #172b49;
$blue9: #0f1d31;
$indigo0: #ecebf2;
$indigo1: #d6d5e4;
$indigo2: #bebbd5;
$indigo3: #a09cc1;
$indigo4: #7873a8;
$indigo5: #2d2577;
$indigo6: #282069;
$indigo7: #221c5a;
$indigo8: #1b1647;
$indigo9: #110e2e;
$violet0: #efeaf2;
$violet1: #ddd4e4;
$violet2: #c9b9d3;
$violet3: #b19ac0;
$violet4: #9070a6;
$violet5: #562577;
$violet6: #4c216a;
$violet7: #421c5b;
$violet8: #351649;
$violet9: #230f30;
$fuschia0: #f2eaf1;
$fuschia1: #e3d3e2;
$fuschia2: #d3b9d0;
$fuschia3: #bf99bb;
$fuschia4: #a56fa0;
$fuschia5: #77256f;
$fuschia6: #6a2163;
$fuschia7: #5c1c56;
$fuschia8: #4a1745;
$fuschia9: #320f2f;
$pink0: #f2eaed;
$pink1: #e4d3da;
$pink2: #d3b9c4;
$pink3: #bf99a9;
$pink4: #a57085;
$pink5: #772546;
$pink6: #6a213e;
$pink7: #5c1c36;
$pink8: #4a172b;
$pink9: #320f1d