.main-wrapper {
    position:relative;
}

.wrapper {
    max-width:1400px;
    margin:0 auto;
}

.main-content {
    margin-left:30%;
    padding: $gutter * 2;
    background-color: $white;
}

.sombra {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:75px;
    overflow:hidden;
    z-index:1;
    background-image:linear-gradient(to top, rgba($black,0.66), transparent)
}

.logo {
    img {
        height:100px;
    } 
}

.sides2 {
    @include breakpoint($medium) {
        display:flex;
    }
}

.sides2__content {
    padding: $gutter;
    @include breakpoint($small) {
        padding: $gutter * 2;
    }
    @include breakpoint($medium) {
        width:50%;
    }

    color: $white;
    p, h1 {
        color: $white;
    }
    a {
        @include set-link($white, $white, $red2, $red2)
    }
    nav {
        text-align:right;
    }
}

.sides2__image {
    @include breakpoint($medium) {
        width:50%;
    }
    overflow:auto !important;
}

.link-layer {
    @include layer();
    z-index:1;
}

#quiensoy,
#clases,
.noticias__wrapper,
#contacto {
    position:relative;
}

.volver {
    position:absolute;
    bottom: - $gutter;
    z-index:999;
    @include breakpoint($medium) {
        bottom: $gutter;
    }
    left:50%;
    transform: translateX(-50%);
    width:$gutter * 2;
    height: $gutter * 2;
    background-color: $white;
    border-radius:50%;
    padding: 3px;
    #quiensoy  & {
        svg {
            fill: $blue4;
        }
        &:hover,
        &:focus {
            background-color: $blue6;
            svg {
                fill: $white;
            }
        }
    }
    #clases & {
        svg {
            fill: $pink4;
        }
        &:hover,
        &:focus {
            background-color: $pink6;
            svg {
                fill: $white;
            }
        }
    }
    #contacto & {
        svg {
            fill: $violet4;
        }
        &:hover,
        &:focus {
            background-color: $violet6;
            svg {
                fill: $white;
            }
        }
    }
    .noticias__wrapper & {
        svg {
            fill: $teal4;
        }
        &:hover,
        &:focus {
            background-color: $teal6;
            svg {
                fill: $white;
            }
        }
    }
}