.clases-grid {
    @extend .grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    align-items: end;
}

.clase {
    position:relative;
    h2 {
        color: $pink1;
    }
}

.clase__inner {
    background: $pink1;
    padding: $gutter;
    height:300px;
    display:flex;
    flex-direction:column;
    position:relative;
}

.clase__horarios {
    margin-top:auto;
    ul {
        margin:0;
        list-style-type:none;
        li {
            margin:0;
        }
    }
}