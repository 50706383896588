.full-img {
    height:100vh;
    overflow:hidden;
    position:relative;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    .full-img__shadow {
        @include layer();
        z-index:2;
        box-shadow: 0 0 700px rgba(#000, 1) inset
    }
}

.cover-img {
    height:100%;
    width:100%;
    object-fit:cover;
}

.testing {
    position:relative;
    .full-img__shadow {
        @include layer();
        z-index:2;
        box-shadow: 0 0 700px rgba(#000, 1) inset
    }
    img {
        width:100%;
    }
}