.noticias__wrapper {
    padding: $gutter;
    @include breakpoint($small) {
        padding: $gutter * 2;
    }
    background-color: $teal4;
    margin:0;
    position:relative;
    h1 {
        color: $white;
        a {
            @include set-link($white, $white, $base, $base);
            @include kill-link-decoration();
        }
    }
}

.noticias__home {
    position:absolute;
    top:$gutter * 2;
    right: $gutter * 2;
    color: $white;
    a {
        @include set-link($white, $white, $base, $base);
    }
}

.noticias-grid {
    display:block;
    @include breakpoint($small) {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint($medium) {
        display:grid;
        grid-template-columns: repeat(3, 1fr);
    }
    @include breakpoint($large) {
        grid-template-columns: repeat(4, 1fr);
    }
    margin:0;
    .noticia:first-child.noticia--destacada {
        grid-row: span 3;
        .noticia__image {
            max-height: 10000px;
        }
    }
    // .noticia:nth-child(2).noticia--destacada,
    // .noticia:nth-child(3).noticia--destacada {
    //     grid-row: span 2;
    // }
}

.grid-item.noticia {
    background-color: $white;
    display:flex;
    flex-direction:column;
    position:relative;
    justify-content:center;
    text-align:center;
    margin-bottom: $gutter;
    .noticia__text {
        margin: 0 $gutter;
    }
    &:hover {
        background-color: $teal1;
    }
    @include breakpoint($medium) {
        margin-bottom: 0;
    }
    &.noticia--destacada {
        justify-content: flex-start;
        text-align:left;
        background-color: $teal2;  
        &:hover {
            background-color: $teal3;
            h3, p {
                color: $white;
            }
        }
        .noticia__text {
            background-color: transparent;
            margin: 0;
            p {
                @include ritmo-font-size($h5-font-size); 
            }   
            a {
                @include set-link($white, $white, $orange1, $orange1);
            }
        }
    } 
}

.noticia__text {
    padding: $gutter;
    display:flex;
    flex-direction:column;
    p:last-child {
        margin:0;
    }
    p.small {
        margin:0;
    }
    h3 {
        font-family: $font-main;
        margin:0;
        @include ritmo-font-size($h4-font-size);
    }
    p {
        @include ritmo-font-size($h6-font-size); 
    }
}

.noticia__image {
    flex:1;
    max-height:200px;
    overflow:hidden;
    .noticias-grid & {
        margin-top:auto;
    }
    img {
        object-fit:cover;
        width:100%;
        height:100%;
    }
}

.noticia-page {
    padding: $gutter * 2;
    min-height:100vh;
    .wrapper {
        display:flex;
        height:100%;
    }
}

.bg--post {
    background-color: $teal4;
}

.bg--clase {
    background-color: $pink4;
}

.noticia-page__header {
    height:calc(100vh - #{$gutter} * 4);
    flex: 1 0 20%;
    position:relative;
    .header {
        width:100%;
        display:block;
        .logo img {
            max-height:150px;
        }
    }
    .main-menu {
        bottom:0;
        left:0;
    }
}

.noticia-page__img {
    height:40vh;
    overflow:hidden;
    img {
        object-fit:cover;
        width:100%;
        height:100%;
    }
}

.noticia-page__content {
    flex: 4 1 auto;
    margin-left: $gutter * 2;
    background-color: $white;
}

.noticia-page__inner {
    padding: $gutter * 2;
}

.grid.noticias {
    display:block;
    @include breakpoint($small) {
        display:grid;
    }
    .grid-item {
        justify-content:flex-start;
    }
}