

@include breakpoint($medium) {
    .section__mitad {
        display:flex;
        height:100vh;
    }
    
    .section__mitad__left {
        width:50%;
        overflow:hidden;
    }

    .section__mitad__right {
        width:50%;
        overflow:hidden;
    }
}