.noticias__header {
    height:75vh;
    position:relative;
    margin:0;
    .logo {
        position:absolute;
        @include breakpoint($small) {
            top: $gutter * 2;
            right: $gutter * 2;
        }
        img {
            height:100px;
        } 
    }
    .full-img {
        height:100%;
    }
}