.header {
    // position:absolute;
    // z-index:3;
    padding: 0;
    width:30%;
    // top:0;
    // right:0;
    // bottom:0;
    margin:0;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:flex-end;
    // background-color: rgba($white, 0.75);
}

.header__wrapper {
    position:relative;
    //max-height:80vh;
    height: calc(100vh - (#{$gutter} * 6));
    display:flex;
    margin-bottom: $gutter * 3;
}

.main-image {
    width:70%;
    overflow:hidden;
    // position:relative;
    // top: -$gutter;
    // left: -$gutter;
    img {
        object-fit:cover;
        object-position: center center;
        width:100%;
        height:100%;
    }
}

.main-image--bottom {
    img {
        object-position: bottom center;
    }
}


.main-image--top {
    img {
        object-position: top center;
    }
}