@import url('https://fonts.googleapis.com/css?family=Muli:300,700');


@font-face {
    font-family: 'Cassanet';
    src: url('../fonts/cassannet_plus_light-webfont.eot');
    src: url('../fonts/cassannet_plus_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/cassannet_plus_light-webfont.woff2') format('woff2'),
         url('../fonts/cassannet_plus_light-webfont.woff') format('woff'),
         url('../fonts/cassannet_plus_light-webfont.ttf') format('truetype'),
         url('../fonts/cassannet_plus_light-webfont.svg#cassannet_plusregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'Cassanet';
//     src: url('../fonts/cassannet_plus_bold-webfont.eot');
//     src: url('../fonts/cassannet_plus_bold-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/cassannet_plus_bold-webfont.woff2') format('woff2'),
//          url('../fonts/cassannet_plus_bold-webfont.woff') format('woff'),
//          url('../fonts/cassannet_plus_bold-webfont.ttf') format('truetype'),
//          url('../fonts/cassannet_plus_bold-webfont.svg#cassannet_plusregular') format('svg');
//     font-weight: bold;
//     font-style: normal;
// }

@font-face {
    font-family: 'Noway';
    src: url('../fonts/noway-regular-webfont.eot');
    src: url('../fonts/noway-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/noway-regular-webfont.woff2') format('woff2'),
         url('../fonts/noway-regular-webfont.woff') format('woff'),
         url('../fonts/noway-regular-webfont.ttf') format('truetype'),
         url('../fonts/noway-regular-webfont.svg#nowayregular') format('svg');
    font-weight: normal;
    font-style: normal;
}