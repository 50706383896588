.home__header {
    height:85vh;
    @include breakpoint($small) {
        height:100vh;
    }
    position:relative;
    margin:0;
    .logo {
        position:absolute;
        top: $gutter;
        right: $gutter;
        img {
            height:75px;
        } 
        @include breakpoint($small) {
            top: $gutter * 2;
            left: auto;
            right: $gutter * 2;
            transform:none;
            img {
                height:100px;
            } 
        }
    }
}

.home__header__bg {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: -1;
    overflow:hidden;
    img {
        object-fit:cover;
        width:100%;
        height:100%;
    }
}

.home__video {
    height:66vh;
    overflow:hidden;
    position:relative;
    margin:0;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    img,
    video {
        object-fit:cover;
        width:100%;
        height:100%;
    }
    video {
        margin:0;
    }
}

.home__video__overlay {
    @include layer();
    z-index:10;
    display:block;
    background-color:rgba($pink4, 0.9);
    cursor:pointer;
    &:hover,
    &:focus {
        background-color:rgba($pink4, 0.66);
        .home__video__play {
            background-color: $pink9;
        }
        .home__video__triangle {
            border-color: transparent transparent transparent $pink1;
        }
    }
}

.home__video__play {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:150px;
    height:75px;
    background-color: $pink7;
    border-radius: 25px;
}

.home__video__triangle {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-13px, -50%);
    width:0;
    height:0;
    border-width: 15px 26px;
    border-style:solid;
    border-color: transparent transparent transparent $pink3;
}

.clases {
    background-color: $pink4;
    padding: $gutter;
    @include breakpoint($small) {
        padding: $gutter * 2;
    }
    margin:0;
    h1 {
        color: $white;
    }
}

@keyframes parpadeo {
    from {
        opacity: 1;
    } 
    to {
        opacity:0.66;
    }
}


.destacada {
    position:absolute;
    bottom: 200px;
    right: $gutter;
    left:$gutter;
    padding: $gutter / 2;
    text-align:center;
    animation: parpadeo 0.7s linear alternate 15;
    @include breakpoint($small) {
        text-align:left;
        bottom: $gutter * 6;
        right: 0 ;
        left:auto;
        max-width:50%;
        padding: $gutter $gutter * 3 $gutter $gutter;
    }
    @include breakpoint($medium) {
        max-width: 33%;
        padding: $gutter $gutter * 4 $gutter $gutter;
    }
    @include breakpoint($large) {
        padding: $gutter $gutter * 5 $gutter $gutter;
    }

    background-color: rgba($white, 0.65);
    margin:0;
    animation
    &:hover {
        background-color: rgba($white, 1);
    }
    .minititle {
        display:none;
        @include breakpoint($small) {
            display:block;
        }
    }
    h1 {
        @include breakpoint(max-width $small) {
            @include ritmo-font-size($h5-font-size);
        }
        color: $black;
        margin:0;
        font-weight: bold;
    }
}