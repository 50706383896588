.clase__header {
    height: 15vh;
    @include breakpoint($small) {
        height:40vh;
    }
    overflow:hidden;
    img {
        object-position: center bottom;
    }
}

.clase__content {
    padding: $gutter * 2.5 $gutter $gutter $gutter;
    @include breakpoint($small) {
        padding: $gutter * 2;
    }
    h1, .h1, 
    h2, .h2,
    h3, .h3,
    h4, .h4, p, ul {
        color: $white;
    }
    a {
        @include set-link($white, $white, $base, $base);
    }
    header {
        position:relative;
        h1 {
            margin:0;
            @include breakpoint($small) {
                max-width:75%;
            }
        }
        nav {
            position:absolute;
            top:- $gutter * 1.5;
            right:0;
            margin:0;
            @include breakpoint($small) {
                top:0;
            }
        }
    }
}

@include breakpoint($medium) {
    .clase__cols {
        display:flex;
        justify-content:space-between;
    }

    .clase__col1,
    .clase__col2,
    .clase__col3 {
        width:32%;
    }
}

