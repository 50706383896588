@import "base/fonts";

@import "../../../node_modules/huesos/dependencies";
@import "config";
@import "../../../node_modules/huesos/huesos";

@import "layout/layout";
@import "layout/home";
@import "layout/section_mitad";
@import "layout/noticias";
@import "layout/clase";

@import "components/menu";
@import "components/header";
@import "components/noticias";
@import "components/quiensoy";
@import "components/fullimg";
@import "components/clases";
@import "components/contacto";



// TODO: llevar a sus eccion

.img-cover {
    object-fit:cover;
    width:100%;
    height:100%;
}

.hide-scrollbar {
    &::-webkit-scrollbar { 
        width: 0 !important;
        display:none !important;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.rrss {
    position:absolute;
    top:$gutter;
    left:$gutter;
    text-align:left;
    @include breakpoint($small) {
        text-align:right;
        top:$gutter  * 2;
        left: $gutter * 2;
    }
    z-index:1;
    ul {
        display:inline-block;
        margin:0;
        padding:0;
        text-align:right;
    }
    li {
        display:inline-block;
        vertical-align:middle;
        margin:0 $gutter / 4 0 0;
        @include breakpoint($small) {
            margin:0 $gutter / 8;
        }
    }
    a {
        display:block;
        &:hover {
            svg {
                fill: $red2;
            }
        }
    }
    svg {
        fill: $white;
        height: 2rem;
        width: auto;
    }
}